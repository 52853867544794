<template>
  <v-container fluid>

    <v-row dense>
      <!-- Facilities -->
      <v-col cols="6">
        <v-card elevation="5" :loading="analyticsLoader">
          <div class="d-flex justify-space-between elevation-3">
            <div class="d-flex align-center">
              <v-img :src="organizationsBlack" class="ml-2" max-height="21" max-width="22"></v-img>
              <div class="text-left my-2 ml-2 mb-2 font-weight-bold font-size16">{{$t('facilities')}}</div>
            </div>
            <div>
              <v-text-field
                  single-line
                  outlined
                  class="py-1 px-1"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  dense
                  v-model="searchPatient"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div>
            <v-data-table
                :no-data-text="$t('no-data-available')"
                :loading-text="$t('loading')"
                :items="filteredBillings"
                :headers="facilityHeaders"
                item-key="patientId"
                fixed-header
                dense
                ref="table"
                :single-select="true"
                class="mx-0 px-0 medical-staff-data-table pointer"
                height="300"
                max-height="300"
                group-by="hospitalData.hospitalName"
                :single-expand="true"
                :items-per-page="-1"
                :search="searchPatient"
                :footer-props="footerProps"
            >
              <template  v-slot:[`group.header`]="{ headers, items, toggle,isOpen, group}">
                <td
                    @click="toggle"
                    class="elevation-1 px-1 py-0 white-background"
                    :colspan="10"
                >
                  <v-row class="px-0" dense>
                    <v-col cols="6" class="d-flex text-left py-0" >
                        <v-btn small icon :ref="group" :data-open="isOpen" class="color-black">
                          <v-icon  small v-if="isOpen" color="primary">mdi-arrow-down-drop-circle</v-icon>
                          <v-icon small v-else color="primary">mdi-arrow-right-drop-circle</v-icon>
                        </v-btn>
                      <span class="table-font-size ml-1" style="margin-top:5px;" v-if="items && items.length > 0">
                        {{items[0].hospitalData.hospitalName}}
                      </span>
                    </v-col>
                    <v-col>
                      <v-tooltip bottom color="primary2">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click.stop="handleFacilityClick(items[0])"
                              small
                              color="blue"
                              class="float-right mt-1"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{$t('view-facility-details')}}</span>
                      </v-tooltip>
                    </v-col>

                  </v-row>
                </td>
              </template>

              <template v-slot:item="{ item , index }">
                <tr style="height:7px; !important;" v-if="index === 0">
                  <td class="color-header text-left" v-for="(header,index) in insideHeaders" :key="index" style="height:7px; !important;">
                    <span class="font-weight-medium font-size11">{{ header.text }}</span>
                  </td>
                </tr>

                <tr>
                  <td class="table-font-size" @click="viewPatient(item)">
                    {{item.patientData?.firstName}} {{item.patientData?.lastName}}
                  </td>
                  <!--                    <td class="text-left font-size12 font-weight-medium" >-->
                  <!--                    {{calculateAge(item.patientData?.birthdate)}}-->
                  <!--                    </td>-->
                  <td class="table-font-size">
                    {{item.patientData.email}}
                  </td>
                  <td>
                    <v-tooltip bottom color="primary2">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click="handlePatientClick(item)"
                            small
                            color="blue"
                            class="float-right mr-n2 mt-1"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{$t('view-patient-details')}}</span>
                    </v-tooltip>
                  </td>

                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <!-- Billings -->
      <v-col cols="6" >
        <v-card  height="408" :loading="analyticsLoader"  elevation="5">
          <div class="d-flex align-center elevation-3 py-3" >
            <v-img class="ml-1" :src="billingsNew" max-height="21" max-width="22"></v-img>
            <div class="text-left font-weight-bold font-size16 ml-1 ">{{$t('billings')}}</div>
          </div>
          <div :style="{ maxHeight: computedMaxHeight }">
            <v-card-text>
              <div class="d-flex justify-center align-center">
                <v-card class="d-flex align-center justify-center full-width py-2">
                  <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                    {{ $t('active') }}
                    <v-avatar
                        color="#09CB09"
                        size="30">
                      <span class="white--text">{{ facilityUserStatus.activeUsers }}</span>
                    </v-avatar>
                  </div>
                  <!--              INACTIVE-->
                  <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                    {{ $t('inactive') }}
                    <v-avatar
                        color="gray"
                        size="30"
                    >
                      <span class="black--text">{{ facilityUserStatus.inactiveUsers }}</span>
                    </v-avatar>
                  </div>
                  <!--              DECEASED-->
                  <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                    {{ $t('deceased') }}
                    <v-avatar
                        color="#000"
                        size="30"
                    >
                      <span class="white--text">{{ facilityUserStatus.deceasedUsers }}</span>
                    </v-avatar>
                  </div>
                </v-card>
              </div>
              <div class="text-center my-0 py-0 mb-2">
                <span class="font-size16 font-weight-bold black--text" >{{$t('billing-performance')}} {{ facilityClicked ? hospitalName : `${patientDataObject.patientData?.firstName} ${patientDataObject.patientData?.lastName || ''}` }}</span>
              </div>
              <div class="d-flex justify-center">
                <v-progress-circular
                    v-if="facilityClicked"
                    class="font-size12 mx-2 px-2 font-weight-bold"
                    :rotate="-90"
                    :size="80"
                    :width="2"
                    :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent99453 : ''"
                    :color="getHospitalSummary(clickedFacilityId)?.percent99453 >= 100 ? 'green' : 'primary'"
                >
                  99453 {{ getHospitalSummary(this.clickedFacilityId)?.percent99453 || '0' }}%
                </v-progress-circular>
                <v-progress-circular
                    v-if="facilityClicked"
                    class="font-size12 mx-2 px-2 font-weight-bold"
                    :rotate="-90"
                    :size="80"
                    :width="2"
                    :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent99454 : null"
                    :color="getHospitalSummary(this.clickedFacilityId)?.percent99454 >= 100 ? 'green' : 'primary'"
                >
                  99454 {{ getHospitalSummary(this.clickedFacilityId)?.percent99454 || '0' }}%
                </v-progress-circular>
                <v-progress-circular
                    v-if="facilityClicked"
                    class="font-size12 mx-2 px-2 font-weight-bold"
                    :rotate="-90"
                    :size="80"
                    :width="2"
                    :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent99457 : ''"
                    :color="getHospitalSummary(this.clickedFacilityId)?.percent99457 >= 100 ? 'green' : 'primary'"
                >
                  99457  {{ getHospitalSummary(this.clickedFacilityId)?.percent99457 || '0' }}%
                </v-progress-circular>
                <v-progress-circular
                    v-if="facilityClicked"
                    class="font-size12 mx-2 px-2 font-weight-bold"
                    :rotate="-90"
                    :size="80"
                    :width="2"
                    :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent994581st : ''"
                    :color="getHospitalSummary(this.clickedFacilityId)?.percent994581st >= 100 ? 'green' : 'primary'"
                >
                  99458 1st {{ getHospitalSummary(this.clickedFacilityId)?.percent994581st || '0' }}%
                </v-progress-circular>
                <v-progress-circular
                    v-if="facilityClicked"
                    class="font-size12 mx-2 font-weight-bold"
                    :rotate="-90"
                    :size="80"
                    :width="2"
                    :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId)?.percent994582nd : ''"
                    :color="getHospitalSummary(this.clickedFacilityId)?.percent994582nd >= 100 ? 'green' : 'primary'"
                >
                  99458 2nd {{ getHospitalSummary(this.clickedFacilityId)?.percent994582nd || '0' }}%
                </v-progress-circular>
                <v-progress-circular
                    v-if="clickedPatient"
                    class="font-size12 mx-2 font-weight-bold"
                    :rotate="-90"
                    :size="80"
                    :width="2"
                    :value="patientDataObject.cptCode99453?.status === 1 ? 100 : 0"
                    :color="patientDataObject.cptCode99453?.status === 1 ? 'green' : 'primary'"
                >
                  <template>
                    <v-icon size="16" class="mr-2 " v-if="patientDataObject.cptCode99453?.status === 1" color="black">mdi-checkbox-marked-outline</v-icon>
                    <v-icon size="16" class="mr-2 " v-else color="black" >mdi-checkbox-blank-outline</v-icon>
                  </template>
                  99453
                </v-progress-circular>
                <v-progress-circular
                    v-if="clickedPatient"
                    :rotate="-90"
                    :size="80"
                    :width="2"
                    :value="patientDataObject.myBillings.percent99454"
                    :color="patientDataObject.myBillings.percent99454 === 100 ? 'green' : 'primary'"
                    class="font-size12 mx-2 font-weight-bold"
                >
                  <template>
                    <v-icon size="16" class="mr-2 " v-if="patientDataObject.cptCode99454?.status === 1" color="black">mdi-checkbox-marked-outline</v-icon>
                    <v-icon size="16" class="mr-2 " v-else color="black" >mdi-checkbox-blank-outline</v-icon>
                  </template>
                  99454
                </v-progress-circular>
                <v-progress-circular
                    v-if="clickedPatient"
                    :rotate="-90"
                    :size="80"
                    :width="2"
                    :value="patientDataObject.myBillings.percent99457"
                    :color="patientDataObject.myBillings.percent99457 === 100 ? 'green' : 'primary'"
                    class="font-size12 mx-3 px-2 font-weight-bold"
                >
                  <div class="d-flex flex-column">
                    <span>99457</span>
                    <span>{{ secToMinV2(patientDataObject.myBillings.seconds99457) }}</span>
                  </div>
                </v-progress-circular>
                <v-progress-circular
                    v-if="clickedPatient"
                    :rotate="-90"
                    :size="80"
                    :width="2"
                    :value="patientDataObject.myBillings.percent994581st"
                    :color="patientDataObject.myBillings.percent994581st === 100 ? 'green' : 'primary'"
                    class="font-size12 mx-3 px-2 font-weight-bold"
                >
                  <div class="d-flex flex-column">
                    <span> 99458 1st</span>
                    <span>{{ secToMinV2(patientDataObject.myBillings.seconds994581st) }}</span>
                  </div>
                </v-progress-circular>
                <v-progress-circular
                    v-if="clickedPatient"
                    :rotate="-90"
                    :size="80"
                    :width="2"
                    :value="patientDataObject.myBillings?.percent994582nd"
                    :color="patientDataObject.myBillings?.percent994582nd === 100 ? 'green' : 'primary'"
                    class="font-size12 mx-3  font-weight-bold"
                >
                  <div class="d-flex flex-column">
                    <span>99458 2nd</span>
                    <span>{{ secToMinV2(patientDataObject.myBillings.seconds994582nd) }}</span>
                  </div>

                </v-progress-circular>
              </div>
              <v-divider class="mt-2"></v-divider>
              <v-row dense class="d-flex justify-center" justify="space-around">
                <!-- Statistics Cards -->
                <!--                <v-col cols="12" sm="6" md="12" xl="3">-->
                <!--                  <v-card height="170">-->
                <!--                      <div class="font-size16 font-weight-bold pt-1">Week 1</div>-->
                <!--                    <div>-->
                <!--                      <v-progress-circular-->
                <!--                          :rotate="360"-->
                <!--                          :size="70"-->
                <!--                          :width="5"-->
                <!--                          :value="100"-->
                <!--                          color="green"-->
                <!--                          class="mt-3"-->
                <!--                      >-->
                <!--                        100-->
                <!--                      </v-progress-circular>-->
                <!--                    </div>-->

                <!--                    <div class="font-size14 font-weight-bold mt-1">Excellent Measurement</div>-->
                <!--                    <div class="d-flex align-center  justify-center">-->
                <!--                      <span class="font-size12">2024-06-01 /</span>-->
                <!--                      <span class="font-size12 ml-1">2024-06-07 </span>-->
                <!--                    </div>-->
                <!--                  </v-card>-->
                <!--                </v-col>-->
                <!--                <v-col cols="12" sm="6" md="12" xl="3">-->
                <!--                  <v-card height="170">-->
                <!--                    <div class="font-size16 font-weight-bold pt-1">Week 2</div>-->
                <!--                      <div>-->
                <!--                        <v-progress-circular-->
                <!--                            :rotate="360"-->
                <!--                            :size="70"-->
                <!--                            :width="5"-->
                <!--                            :value="85"-->
                <!--                            color="blue"-->
                <!--                            class="mt-3"-->
                <!--                        >-->
                <!--                          85-->
                <!--                        </v-progress-circular>-->
                <!--                      </div>-->
                <!--                    <div class="font-size14 font-weight-bold mt-1">Average Measurements </div>-->
                <!--                    <div class="d-flex align-center  justify-center">-->
                <!--                      <span class="font-size12">2024-06-08 /</span>-->
                <!--                      <span class="font-size12 ml-1">2024-06-15 </span>-->
                <!--                    </div>-->
                <!--                  </v-card>-->
                <!--                </v-col>-->

                <!--                <v-col cols="12" sm="6" md="12" xl="3">-->
                <!--                  <v-card height="170">-->
                <!--                    <div class="font-size16 font-weight-bold pt-1">Week 3</div>-->
                <!--                    <div>-->
                <!--                      <v-progress-circular-->
                <!--                          :rotate="360"-->
                <!--                          :size="70"-->
                <!--                          :width="5"-->
                <!--                          :value="50"-->
                <!--                          color="orange"-->
                <!--                          class="mt-3"-->
                <!--                      >-->
                <!--                        50-->
                <!--                      </v-progress-circular>-->
                <!--                    </div>-->
                <!--                    <div class="font-size14 font-weight-bold mt-1"> Satisfactory Measruement</div>-->
                <!--                    <div class="d-flex align-center  justify-center">-->
                <!--                      <span class="font-size12">2024-06-16 /</span>-->
                <!--                      <span class="font-size12 ml-1">2024-06-23 </span>-->
                <!--                    </div>-->
                <!--                  </v-card>-->
                <!--                </v-col>-->

                <!--                <v-col cols="12" sm="6" md="12" xl="3">-->
                <!--                  <v-card height="170">-->
                <!--                    <div class="font-size16 font-weight-bold pt-1">Week 4</div>-->
                <!--                    <div>-->
                <!--                      <v-progress-circular-->
                <!--                          :rotate="360"-->
                <!--                          :size="70"-->
                <!--                          :width="5"-->
                <!--                          :value="20"-->
                <!--                          color="red"-->
                <!--                          class="mt-2"-->
                <!--                      >-->
                <!--                        20-->
                <!--                      </v-progress-circular>-->
                <!--                    </div>-->
                <!--                    <div class="font-size14 font-weight-bold mt-2">Bad Performance </div>-->
                <!--                    <div class="d-flex align-center  justify-center">-->
                <!--                      <span class="font-size12">2024-06-24 /</span>-->
                <!--                      <span class="font-size12 ml-1">2024-06-30 </span>-->
                <!--                    </div>-->
                <!--                  </v-card>-->
                <!--                </v-col>-->

                <v-col cols="12">
                  <v-card class="mb-n5" elevation="0">
                    <div class="d-flex align-center pa-3 mt-2">
                      <v-row dense class="d-flex justify-center mt-5" justify="space-around">
                        <v-col cols="12">
                          <v-card>
                            <div class="d-flex align-center pa-2 my-n5">
                              <v-progress-linear
                                  class="white--text"
                                  striped
                                  :value="getOverAllProgressFor(clickedFacilityId).toFixed(1)"
                                  :color="progressColor"
                                  height="25">
                                {{$t('progress')}}:
                                {{ getOverAllProgressFor(clickedFacilityId).toFixed(1) }} %
                              </v-progress-linear>
                            </div>

                          </v-card>
                        </v-col>
                      </v-row>
                    </div>

                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="my-0 py-0 mt-n4">
      <!-- Medical Staff -->
      <v-col v-if="false" cols="3">
        <v-card height="45vh" elevation="3">
          <div  :class="this.$vuetify.breakpoint.lgAndDown ? '' : 'd-flex justify-space-between'">
            <div class="d-flex align-center">
              <img class="ml-2" height="20px" :src="DoctorBlack"/>
              <span class="ml-1 my-2 font-weight-bold font-size16 ">{{ $t('staff') }}</span>
            </div>
            <div>
              <v-text-field
                  single-line
                  class="pa-1"
                  outlined
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  dense
                  v-model="staffSearch"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mt-3">
            <v-data-table
                :loading="medicalStaffTableLoader"
                :headers="staffHeaders"
                :items="combinedData"
                :search="staffSearch"
                height="35vh"
                fixed-header
                class="mt-n3 pointer"
                :footer-props="footerProps"
                :items-per-page="-1"
                :no-data-text="$t('no-data-available')"
                sort-by="roleId"
                hide-default-footer
            >
              <template v-slot:item="{item}">
                <tr :class="item.notes.length > 0 || item.measurements.length > 0 ? 'font-weight-bold table-font-size secondary' : 'font-weight-medium table-font-size'">
                  <td style="height:20px !important;" class="table-font-size px-2 py-0">{{ item?.fullName }} ({{item.roleData.roleNameForDisplay}})</td>
                  <td style="height:20px !important;" class="table-font-size pl-8 py-0">{{ item.notes.length }}/{{ item.measurements.length }}</td>
                  <td style="height:20px !important;" class="table-font-size px-0 py-0">
                    <v-tooltip bottom color="primary2">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="staffClicked(item)"
                            small
                            color="blue"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{$t('view-staff-details')}}</span>
                    </v-tooltip>
                  </td>

                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <!-- Medical Notes -->
      <v-col cols="6">
        <v-card elevation="3">
          <div :class="this.$vuetify.breakpoint.lgAndDown ? '' : 'd-flex justify-space-between'">
            <div class="d-flex align-center">
              <v-icon class="ml-2" color="black" size="20">mdi-note-multiple</v-icon>
              <span class="ml-1 my-2 font-weight-bold font-size16 ">{{ $t('medical-notes') }}</span>
            </div>
            <div>
              <v-text-field
                  single-line
                  outlined
                  class="py-1 px-1"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  dense
                  v-model="searchNotes"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <!--          -->
          <div class="mt-3">
            <v-data-table
                ref="table"
                :no-data-text="$t('no-data-available')"
                :loading-text="$t('loading')"
                :items="clickedPatient ? notesForPatient : (medicalStaffClicked ? medicalNotesByStaff : medicalNotesForSelectedHospital)"
                fixed-header
                dense
                :loading="medicalStaffTableLoader"
                :headers="headers"
                group-by="medicalNoteData.medicalNoteForPatientId"
                item-key="medicalNoteData.id"
                class="mt-n3 pointer"
                :height="$vuetify.breakpoint.width <= 1338 ? '26vh' : '32vh'"
                :expanded.sync="expanded"
                :single-expand="true"
                :footer-props="footerProps"
                :items-per-page="-1"
                :search="searchNotes"
                sort-by="medicalNoteData.timeAdded"
                sort-desc
            >
              <template  v-slot:[`group.header`]="{ headers, items, toggle, group, isOpen}">
                <td @click="toggle" class="elevation-1 px-1 py-0 white-background" :colspan="8">
                  <v-row class="px-0" dense>
                    <v-col cols="4" class="d-flex text-left py-0">
                      <span class="table-font-size ml-1 mt-2">
                       {{ getPatientNameForNotes(items[0].medicalNoteData.medicalNoteForPatientId) }}
                      </span>
                    </v-col>
                    <v-col cols="8" class="text-right py-0 pr-4">
                      <v-btn class="my-0 " small icon :ref="group" :data-open="isOpen">
                        <v-icon size="20" v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                        <v-icon size="20" v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </template>
              <template v-slot:item="{ item, group, isOpen }">
                <tr  @click="expand(item)">
                  <td class="table-font-size">
                    <span class="float-left">{{ item.medicalNoteData.medicalNoteName }}</span>
                  </td>
                  <td class="table-font-size">
                    <span>
                      {{ formatDateUsingLocalesFromUTC(item.medicalNoteData.timeAdded) }}
                    </span>
                  </td>
                  <td></td>

                  <td class="text-right">
                    <v-btn small icon :ref="group" :data-open="isOpen">
                      <v-icon size="20" v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                      <v-icon size="20" v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <tr>
                  <td class="table-header text-left header-cell medical-staff" style=" height:5px !important;">
                    <span class="font-weight-medium font-size11 pl-2">{{ noteHeaders[0].text }}</span>
                  </td>

                  <td class="table-header text-left header-cell medical-staff" style=" height:5px !important;">
                    <span class="font-weight-medium font-size11 pl-3">{{ noteHeaders[1].text }}</span>
                  </td>
                  <td class="table-header text-left header-cell medical-staff" style=" height:5px !important;">
                    <span class="font-weight-medium font-size11 pl-3"></span>
                  </td>
                  <td class="table-header text-left header-cell medical-staff" style=" height:5px !important;">
                    <span class="font-weight-medium font-size11 pl-3"></span>
                  </td>
                </tr>
                <tr v-for="(detail) in item.medicalNoteData.medicalNoteDetails" :key="detail.id">
                  <td class="text-left color-black font-size11 medical-staff-cell pa-0 pl-2">
                    {{ medicalStaffName(item.medicalNoteData.createdByMedicalStaffId) }}
                  </td>
                  <td class="text-left color-black font-size11 note-detail-cell pl-1" >
                    <div class="overflow-auto pl-2">
                      <span v-html="formatContent(detail.medicalNoteContent)"></span>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <!--                    <td>-->
                  <!--                      <v-icon :disabled="!checkIfiCanDeleteAdditionalNote(detail)" small color="primary" @click.stop="deleteUserConfirm(detail)">mdi mdi-delete</v-icon>-->
                  <!--                    </td>-->
                </tr>
              </template>
            </v-data-table>

          </div>
        </v-card>
      </v-col>
      <!-- Measurements -->
      <v-col cols="6">
        <v-card elevation="3">
          <div :class="$vuetify.breakpoint.lgAndDown  ? '' : 'd-flex justify-space-between'">
            <div class="d-flex align-center">
              <img class="ml-2" height="20px" :src="bloodPressureIcon"/>
              <span class="ml-1 my-2 font-weight-bold font-size16 ">{{ $t('measurements') }}</span>
            </div>
            <div>
              <v-text-field
                  single-line
                  class="py-1 px-1"
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                  outlined
                  dense
                  v-model="measurementsSearch"
                  :label="$t('search')"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mt-3">
            <v-data-table
                :loading="medicalStaffTableLoader"
                :items="clickedPatient ? measurementForPatient : (medicalStaffClicked ? measurementsByStaff : measurementsForSelectedHospital)"
                :headers="measurementHeaders"
                :height="$vuetify.breakpoint.width <= 1338 ? '26vh' : '32vh'"
                fixed-header
                class="mt-n3 pointer"
                :search="measurementsSearch"
                :no-data-text="$t('no-data-available')"
                :loading-text="$t('loading')"
                :footer-props="footerProps"
                :items-per-page="-1"
                group-by="patientId"
                ref="measurementTable"
            >
              <template  v-slot:[`group.header`]="{ headers, items, toggle, group, isOpen}">
                <td @click="toggle" class="elevation-1 px-1 py-0 white-background" :colspan="8" style=" height:31px !important;">
                  <v-row class="px-0" dense>
                    <v-col cols="4" class="d-flex text-left py-0">
                      <span class="table-font-size ma-2">
                        {{ getPatientNameForMeasurements(items[0].patientId) }}
                      </span>
                    </v-col>
                    <v-col cols="8" class="text-right py-0 pr-4">
                      <v-btn class="my-0 pt-2" small icon :ref="group" :data-open="isOpen">
                        <v-icon  small v-if="isOpen" color="primary">mdi-arrow-down-drop-circle</v-icon>
                        <v-icon small v-else color="primary">mdi-arrow-left-drop-circle</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </template>
              <template v-slot:item="{ item }">
                <tr :class="{'chips-icon': item.isAbnormal}">
                  <!--                  <td class="font-size12 font-weight-medium text-left" style="height:25px;" >-->
                  <!--                    {{ getPatientNameForMeasurements(item.patientId) }}-->
                  <!--                  </td>-->
                  <td class="table-font-size d-flex align-center" style="height:25px;">
                    <v-img :src="getDeviceIcons(item)" max-width="20"  class="mr-2"></v-img>
                    {{item.deviceName}}
                  </td>
                  <td class="table-font-size" style="height:25px;">
                    {{ formatMeasurement(JSON.parse(item.measuredDataJson)) }}
                  </td>
                  <td class="table-font-size" style="height:25px;">
                    {{ formatDate(item.measuredAtUtc)  }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  bloodPressureBlack,
  glucoseMeterBlack,
  oxymeterBlack,
  scaleBlack,
  thermometerBlack,
  billBlack,
  organizationsBlack, doctorBlack,
} from '@/assets';

export default {
  data () {
    return {
      search: '',
      searchPatient: '',
      DoctorBlack: doctorBlack,
      hospitalName: null,
      hospitalData: [],
      expanded: [],
      billingsNew: billBlack,
      headers: [
        { text: this.$t('MedicalNote'), value: 'medicalNoteData.medicalNoteName', class: 'table-header height15-black pl-2', sortable: false, align: 'start' },
        { text: this.$t('created'), value: 'medicalNoteData.medicalNoteName', class: 'table-header height15-black pl-5', sortable: false, align: 'start' },
        { text: this.$t(''), class: 'table-header height15-black', sortable: false, align: 'start' },
        { text: this.$t(''), class: 'table-header height15-black', sortable: false, align: 'start' },
      ],
      searchNotes: '',
      clickedPatient: false,
      patientDataObject: null,
      measurementsSearch: '',
      staffSearch: '',
      combinedData: [],
      hospitalSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      medicalNotesByStaff: [],
      measurementForPatient: [],
      measurementsByStaff: [],
      medicalNotesForSelectedHospital: [],
      measurementsForSelectedHospital: [],
      medicalStaffClicked: false,
      medicalStaffTableLoader: false,
      medicalNotesTableLoader: false,
      facilitySearch: '',
      measurementsTableLoader: false,
      facilitiesTableLoader: false,
      analyticsLoader: false,
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      sortBy: 'noteTimestamp',
      sortDesc: true,
      lastValidHospitalId: null,
      billingSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      clickedFacilityId: null,
      oximeterIcon: oxymeterBlack,
      glucoseIcon: glucoseMeterBlack,
      bloodPressureIcon: bloodPressureBlack,
      thermometerIcon: thermometerBlack,
      weightScaleIcon: scaleBlack,
      insideHeaders: [
        { text: this.$t('patient-name'), value: 'medicalNoteCategoryName', sortable: true, class: 'primary', align: 'start' },
        // { text: this.$t('age'), sortable: false, class: 'color-header', align: 'start' },
        { text: this.$t('email'), sortable: false, class: 'color-header', align: 'start' },
        { text: '', align: 'start', value: '', groupable: false, sortable: false },
      ],
      facilityHeaders: [
        { text: this.$t('hospital-name'), value: 'hospitalData.hospitalName', align: 'start', sortable: false },
        { text: this.$t(''), class: 'white', value: 'patientData.email', align: 'start', sortable: false },
        { text: this.$t(''), class: 'white', value: ' patientData.firstName', sortable: false },
        { text: this.$t(''), class: 'white', align: 'start', sortable: false },
        // { text: this.$t(''), class: 'white', align: 'start' },
      ],
      facilityClicked: true,
      noteHeaders: [
        { text: this.$t('staff'), class: 'table-header height15-black', sortable: false, align: 'start pl-2' },
        { text: this.$t('note-description'), class: 'table-header height15-black', sortable: false, align: 'start pl-4' },
      ],
      noteHeaders1: [
        { text: this.$t('patient-name'), value: 'medicalNoteData.medicalNoteDetails[0].medicalNoteContent', class: 'table-header height15-black', sortable: false, align: 'start pl-2' },
        // { text: this.$t('patient'), class: 'table-header height15-black', sortable: false, align: 'start pl-2' },
        { text: this.$t(''), class: 'px-0 mx-0', sortable: false },
        { text: this.$t(''), class: 'table-header text-end height15-black ', sortable: false, align: 'end pr-5' },
        { text: this.$t(''), class: 'table-header text-end height15-black ', sortable: false, align: 'end pr-5' },
      ],
      measurementHeaders: [
        // { text: this.$t('patient-name'), value: '', sortable: false, class: 'text-start pl-5 height15-black', width: 150 },
        { text: this.$t('device'), value: '', sortable: false, class: ' text-start pl-5 height15-black', width: 150 },
        { text: this.$t('measurements'), class: 'height15-black', value: 'deviceName', align: 'start', width: 180 },
        { text: this.$t('date'), class: 'height15-black text-start', value: 'deviceName', align: 'start', width: 160 },
      ],
      staffHeaders: [
        { text: this.$t('name'), value: 'fullName', class: 'px-0 py-0 pl-2 height15-black' },
        { text: this.$t('notes-measurements'), value: 'notes', class: 'px-0 py-0 height15-black', width: '50' },
        { text: '', value: '', class: 'px-0 py-0 height15-black', width: '50' },
      ],
      facilityUserStatus: {},
    };
  },
  computed: {
    computedMaxHeight () {
      return this.$vuetify.breakpoint.width < 1338 ? '500px' : '600px';
    },
    organizationsBlack () {
      return organizationsBlack;
    },
    progressColor () {
      const progress = this.getOverAllProgressFor(this.clickedFacilityId).toFixed(1);
      if (progress < 75) {
        return 'red'; // Color for progress less than 50
      } else if (progress >= 75 && progress < 90) {
        return 'orange'; // Color for progress between 50 and 75
      } else {
        return 'green'; // Color for progress 75 and above
      }
    },
    ...mapState({
      // selectedMonthAndYear: (state) => state.filterbar.billingDate,
      authUserData: (state) => state.authentication.userData,
      billingsFromRepo: state => state.billings.billingSummaryForMonth,
      medicalStaff: (state) => state.hospitals.medicalStaff,
      medicalNotesForHospital: (state) => state.appointments.medicalNotesForHospital,
      patientMeasurementsData: (state) => state.medicaldevice.patientMeasurements,
      loggedUser: (state) => state.authentication.userData,
      notesForPatient: (state) => state.appointments.notes,
    }),

    ...mapGetters({
      selectedMonthAndYear: 'filterbar/getBillingDate',
      roleName: 'authentication/getRole',
      userSettings: 'authentication/getUserSettings',
    }),
    patientIdToNameMapForNotes () {
      const map = {};
      this.billingsFromRepo.forEach(billing => {
        const patient = billing?.patientData;
        if (patient && patient.id) {
          map[patient.id] = `${patient.firstName} ${patient.lastName}`;
        }
      });
      return map;
    },
    patientIdToNameMapForMeasurements () {
      const map = {};
      this.billingsFromRepo.forEach(billing => {
        const patient = billing?.patientData;
        if (patient && patient.id) {
          map[patient.id] = `${patient.firstName} ${patient.lastName}`;
        }
      });
      return map;
    },
    filteredBillings () {
      const searchTerms = this.facilitySearch.toLowerCase().split(' ');
      return this.billingsFromRepo.filter(item => {
        const email = item.patientData?.email.toLowerCase();
        const firstName = item.patientData?.firstName.toLowerCase();
        return searchTerms.every(term =>
            email?.includes(term) ||
            firstName?.includes(term),
        );
      });
    },
  },
  async created () {
    await this.getbillingsummaryforallpatientsformonth();
    await this.getStaff();
    await this.getMeasurementsForHospital(localStorage.getItem('selectedHospitalAssignedId'));
    await this.getMedicalNotesForHospital(localStorage.getItem('selectedHospitalAssignedId'));
    this.combineData();
    this.aggregateData();
  },
  async mounted () {
    this.toggleHeaders();
    this.hospitalName = this.billingsFromRepo[0]?.hospitalData?.hospitalName;
  },
  watch: {
    selectedMonthAndYear (val) {
      if (val) {
        this.handleFacilityClick();
        this.getbillingsummaryforallpatientsformonth();
        this.calculatePatientStatusForFacility();
        this.getStaff();
        this.getMeasurementsForHospital(localStorage.getItem('selectedHospitalAssignedId'));
        this.getMedicalNotesForHospital(localStorage.getItem('selectedHospitalAssignedId'));
        this.combineData();
        this.aggregateData();
      }
    },
  },
  methods: {
    getOverAllProgressFor (selectedHospitalId) {
      if (this.patientDataObject && this.clickedPatient) {
        var result1 = ((this.patientDataObject.myBillings?.percent99454 * (1 / 4)) + (this.patientDataObject.myBillings?.percent99457 * (1 / 4)) +
            (this.patientDataObject.myBillings?.percent994581st * (1 / 4)) + (this.patientDataObject.myBillings?.percent994582nd * (1 / 4)));
        if (isNaN(result1)) result1 = 0;

        return result1;
      } else {
        var result2 = ((this.getHospitalSummary(selectedHospitalId)?.percent99454 * (1 / 4)) + (this.getHospitalSummary(selectedHospitalId)?.percent99457 * (1 / 4)) +
            (this.getHospitalSummary(selectedHospitalId)?.percent994581st * (1 / 4)) + (this.getHospitalSummary(selectedHospitalId)?.percent994582nd * (1 / 4)));
        if (isNaN(result2)) result2 = 0;

        return result2;
      }
    },
    calculatePatientStatusForFacility () {
      // Count based on the value of the properties if they are true
      this.facilityUserStatus = this.billingsFromRepo.reduce((acc, item) => {
        if (item.patientData !== null && item.patientData !== undefined) {
          if (item.patientData.status === 1) acc.activeUsers++;
          if (item.patientData.status !== 1 && item.patientData.status !== 99) acc.inactiveUsers++;
          if (item.patientData.status === 99) acc.deceasedUsers++;
        }
        return acc;
      }, { activeUsers: 0, inactiveUsers: 0, deceasedUsers: 0 });
    },
    viewPatient (val) {
      this.$router.push(`/patientdetails/${val.patientData.id}`);
    },
    async handleFacilityClick (val) {
      this.hospitalName = val?.hospitalData?.hospitalName;
      // this.medicalStaffTableLoader = true;
      this.clickedPatient = false;
      this.facilityClicked = true;
      if (this.facilityClicked === true) {
        this.clickedPatient = false;
      }
      if (this.clickedPatient === true) {
        this.facilityClicked = false;
      }
      this.medicalStaffClicked = false;
      if (val === undefined) {
        if (this.lastValidHospitalId !== null) {
          this.clickedFacilityId = this.lastValidHospitalId;
        } else {
          return;
        }
      } else {
        this.clickedFacilityId = val.hospitalId;
        this.lastValidHospitalId = val.hospitalId;
      }
      this.analyticsLoader = true;
      this.medicalStaffTableLoader = true;
      await this.getStaff();
      await this.getMeasurementsForHospital(this.clickedFacilityId);
      await this.getMedicalNotesForHospital(this.clickedFacilityId);
      this.combineData();
      await this.getbillingsummaryforallpatientsformonth();
      // this.medicalStaffTableLoader = false;
    },
    async getNotesForPatient (item) {
      this.medicalStaffTableLoader = true;
      const body = {
        patientId: item.patientId,
        hospitalId: localStorage.getItem('selectedHospitalAssignedId'),
      };
      await this.$store.dispatch('appointments/getmedicalnoteforpatientbyid', body).then(res => {
        this.medicalStaffTableLoader = false;
        this.toggleHeaders();
      });
    },
    async expand (item) {
      if (item === this.expanded[0]) this.expanded = [];
      else this.expanded = [item];
    },

    secToMinV2 (seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${remainingSeconds}s`;
    },
    handlePatientClick (item) {
      this.getNotesForPatient(item);
      this.getMeasurementsForHospital(this.clickedFacilityId, item.patientId, item.hospitalId);
      this.patientDataObject = item;
      this.clickedPatient = true;
      this.facilityClicked = false;
    },
    getHospitalSummary (selectedHospitalId) {
      let hospitalId = null;
      if (selectedHospitalId === null) {
        hospitalId = localStorage.getItem('selectedHospitalAssignedId');
      } else {
        hospitalId = selectedHospitalId;
      }

      if (!hospitalId) {
        return null;
      }

      if (hospitalId in this.hospitalData) {
        return this.hospitalData[hospitalId];
      } else {
        return null;
      }
    },
    aggregateData () {
      const aggregatedData = {};

      for (const entry of this.billingsFromRepo) {
        if (entry.id === 0) { break; }
        const hospitalId = entry.hospitalData.id;

        if (!(hospitalId in aggregatedData)) {
          aggregatedData[hospitalId] = {
            counter: 0,
            counter99453: 0,
            percent99453: 0.0,
            counter99454: 0,
            percent99454: 0.0,
            counter99457: 0,
            percent99457: 0.0,
            counter994581st: 0,
            percent994581st: 0.0,
            counter994582nd: 0,
            percent994582nd: 0.0,
          };
        }

        aggregatedData[hospitalId].counter += 1;

        if (entry.cptCode99453?.status === 1) {
          aggregatedData[hospitalId].counter99453 += 1;
        }
        if (entry.cptCode99454?.status === 1) {
          aggregatedData[hospitalId].counter99454 += 1;
        }
        if (entry.cptCode99457?.status === 1) {
          aggregatedData[hospitalId].counter99457 += 1;
        }
        if (entry.cptCode99458First?.status === 1) {
          aggregatedData[hospitalId].counter994581st += 1;
        }
        if (entry.cptCode99458Second?.status === 1) {
          aggregatedData[hospitalId].counter994582nd += 1;
        }
      }

      for (const hospitalId in aggregatedData) {
        const totalCounter = aggregatedData[hospitalId].counter;
        if (totalCounter > 0) {
          aggregatedData[hospitalId].percent99453 = ((aggregatedData[hospitalId].counter99453 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent99454 = ((aggregatedData[hospitalId].counter99454 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent99457 = ((aggregatedData[hospitalId].counter99457 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent994581st = ((aggregatedData[hospitalId].counter994581st / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent994582nd = ((aggregatedData[hospitalId].counter994582nd / totalCounter) * 100).toFixed(1);
        }
      }

      var patientNumber = this.billingsFromRepo.length;

      this.billingSummary.cpt99453 = 0;
      this.billingSummary.cpt99454 = 0;
      this.billingSummary.cpt99457 = 0;
      this.billingSummary.cpt994581st = 0;
      this.billingSummary.cpt994582nd = 0;

      if (patientNumber > 0) {
        this.billingsFromRepo.forEach((patient, index) => {
          if (patient.cptCode99453?.status === 1) {
            this.billingSummary.cpt99453++;
          }
          if (patient.cptCode99454?.status === 1) {
            this.billingSummary.cpt99454++;
          }
          if (patient.cptCode99457?.status === 1) {
            this.billingSummary.cpt99457++;
          }
          if (patient.cptCode99458First?.status === 1) {
            this.billingSummary.cpt994581st++;
          }
          if (patient.cptCode99458Second?.status === 1) {
            this.billingSummary.cpt994582nd++;
          }

          let percent99454 = 0;
          let totalMeasurements99454 = 0;
          let measurement99454IsBillable = false;
          let measurement99457IsBillable = false;
          let measurement994581stIsBillable = false;
          let measurement994582ndIsBillable = false;
          if (patient.cptCode99454 !== null) {
            if (patient.cptCode99454.totalMeasurements < 16) {
              percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
              totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
            } else {
              totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
              percent99454 = 100;
              measurement99454IsBillable = true;
            }
          }

          const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

          let percent99457 = 0;
          let percent994581st = 0;
          let percent994582nd = 0;

          let seconds99457 = 0;
          let seconds994581st = 0;
          let seconds994582nd = 0;

          if (totalSec < 1200) {
            seconds99457 = totalSec;
            percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

            seconds994581st = 0;
            percent994581st = 0;

            seconds994582nd = 0;
            percent994582nd = 0;
          }

          if (totalSec >= 1200 && totalSec < 2400) {
            seconds99457 = 1200;
            percent99457 = 100;
            measurement99457IsBillable = true;

            seconds994581st = totalSec - 1200;
            percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

            seconds994582nd = 0;
            percent994582nd = 0;
          }

          if (totalSec >= 2400 && totalSec < 3600) {
            seconds99457 = 1200;
            percent99457 = 100;

            seconds994581st = 1200;
            percent994581st = 100;
            measurement994581stIsBillable = true;

            seconds994582nd = totalSec - 2400;
            percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
          }

          if (totalSec >= 3600) {
            seconds99457 = 1200;
            percent99457 = 100;

            seconds994581st = 1200;
            percent994581st = 100;

            seconds994582nd = totalSec - 2400;
            percent994582nd = 100;
            measurement994582ndIsBillable = true;
          }
          const myBillings = {
            totalMeasurements99454: totalMeasurements99454,
            percent99454: percent99454,
            percent99457: percent99457,
            percent994581st: percent994581st,
            percent994582nd: percent994582nd,
            seconds99457: seconds99457,
            seconds994581st: seconds994581st,
            seconds994582nd: seconds994582nd,
            m99454IsBillable: measurement99454IsBillable,
            m99457IsBillable: measurement99457IsBillable,
            m994581stIsBillable: measurement994581stIsBillable,
            m994582ndIsBillable: measurement994582ndIsBillable,
          };
          patient.myBillings = myBillings;
        });
        this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);
      }
      this.hospitalData = aggregatedData;
    },
    getDeviceNames (device) {
      switch (device.deviceType) {
        case 5:
          return this.$t('weight-scale');
        case 3:
          return this.$t('blood-pressure');
        case 2:
          return this.$t('oximeter');
        case 1:
          return this.$t('thermometer');
        case 6:
        case 7: // Include both 6 and 7 for glucose-meter
          return this.$t('glucose-meter');
        default:
          return this.$t('unknown-device');
      }
    },
    getPatientNameForNotes (patientId) {
      return this.patientIdToNameMapForNotes[patientId] || 'Unknown Patient';
    },
    getPatientNameForMeasurements (patientId) {
      return this.patientIdToNameMapForMeasurements[patientId] || 'Unknown Patient';
    },
    staffClicked (val) {
      this.clickedPatient = false;
      this.facilityClicked = true;
      this.medicalNotesTableLoader = true;
      this.measurementsTableLoader = true;
      this.medicalStaffClicked = true;
      this.medicalNotesByStaff = val.notes;
      this.measurementsByStaff = val.measurements;

      this.medicalNotesByStaff.sort((a, b) => new Date(b.measuredAtUtc) - new Date(a.measuredAtUtc));
      this.measurementsByStaff.sort((a, b) => new Date(b.measuredAtUtc) - new Date(a.measuredAtUtc));
      setTimeout(() => {
        this.toggleHeaders();
      }, 100);
      this.medicalNotesTableLoader = false;
      this.measurementsTableLoader = false;
    },
    combineData () {
      this.combinedData = this.medicalStaff.map(staff => {
        // Filter notes created by this staff member
        const staffNotes = this.medicalNotesForHospital.flatMap(patient =>
            patient.medicalNotes.filter(note => note.medicalNoteData.createdByMedicalStaffId === staff.id),
        );
        // Filter measurements created by this staff member
        const staffMeasurements = this.patientMeasurementsData.flatMap(patient =>
            patient.medicalDeviceMeasurementsV2
                .filter(measurement => measurement.measurementDoneByUserId === staff.id)
                .map(measurement => ({
                  ...measurement,
                  deviceName: this.getDeviceNames(measurement), // Add deviceName based on deviceType
                  patientId: patient.patientId,
                })),
        );
        const fullName = `${staff.firstName} ${staff.lastName}`;

        return {
          ...staff,
          fullName: fullName,
          notes: staffNotes,
          measurements: staffMeasurements,
        };
      });
      // Return all notes from the provided object
      this.medicalNotesForSelectedHospital = this.medicalNotesForHospital.flatMap(patient => patient.medicalNotes);

      this.measurementsForSelectedHospital = this.patientMeasurementsData.flatMap(patient =>
          patient.medicalDeviceMeasurementsV2.map(measurement => ({
            ...measurement,
            patientId: patient.patientId, // Add patientId to each measurement
            deviceName: this.getDeviceNames(measurement), // Add deviceName based on deviceType
          })),
      );
      this.measurementsForSelectedHospital.sort((a, b) => new Date(b.measuredAtUtc) - new Date(a.measuredAtUtc));
      this.medicalNotesForSelectedHospital.sort((a, b) => new Date(b.measuredAtUtc) - new Date(a.measuredAtUtc));

      setTimeout(() => {
        this.toggleHeaders();
      }, 100);
      this.medicalStaffTableLoader = false;
    },

    filterOutPatientMeasurement (patientId) {
      const patient = this.patientMeasurementsData.find(patient => patient.patientId === patientId);
      if (patient) {
        this.measurementForPatient = patient.medicalDeviceMeasurementsV2.map(measurement => ({
          patientId: patient.patientId,
          deviceName: this.getDeviceNames(measurement),
          ...measurement,
        }));
        // Sort by measuredAtUtc in ascending order
        this.measurementForPatient.sort((a, b) => new Date(b.measuredAtUtc) - new Date(a.measuredAtUtc));
      }
    },
    async getMeasurementsForHospital (facilityId, patientId, hospitalId) {
      const [year, month] = this.selectedMonthAndYear.split('-');
      const body = {
        hospitalId: facilityId || hospitalId,
        byMonth: month,
        byYear: year,
      };
      await this.$store.dispatch('medicaldevice/getmeasurementsforpatientsforhospital', body)
          .then(() => {
            this.filterOutPatientMeasurement(patientId);
          });
    },
    async getMedicalNotesForHospital (facilityId) {
      const [year, month] = this.selectedMonthAndYear.split('-');
      const body = {
        hospitalId: facilityId,
        byMonth: month,
        byYear: year,
      };
      await this.$store.dispatch('appointments/getmedicalnotesforhospital', body)
    .then(() => {
        this.loading = false;
      });
    },
    async getStaff () {
      this.loading = true;
      if (this.roleName === 'ORGANIZATION_ADMIN') {
        const organizationId = this.loggedUser.organizationId;
        await this.$store
            .dispatch('medicalInstitutions/getRelatedOrganizationMedicalStaff', organizationId)
            .then(() => {
              this.loading = false;
            });
      } else if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
        const hospitalId = localStorage.getItem('selectedHospitalAssignedId');
        await this.$store
            .dispatch('hospitals/getRelatedMedicalStaff', hospitalId)
            .then(() => {
              this.loading = false;
            });
      }
    },
    medicalStaffName (staffId) {
      if (this.medicalStaff && this.medicalStaff.length > 0) {
        const matchedStaff = this.medicalStaff.find(staff => staff.id === staffId);
        if (matchedStaff) {
          return `${matchedStaff.firstName} ${matchedStaff.lastName}`;
        }
      }
      return '';
    },
    formatDateUsingLocalesFromUTC (dateTime) {
      return new Date(dateTime).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    },
    formatContent (content) {
      return content.replace(/\n/g, '<br>');
    },
    getDeviceIcons (device) {
      switch (device.deviceType) {
        case 5 :
          return this.weightScaleIcon;
        case 3:
          return this.bloodPressureIcon;
        case 2:
          return this.oximeterIcon;
        case 1:
          return this.thermometerIcon;
        case 6 :
          return this.glucoseIcon;
      }
    },
    formatDate (val) {
      // Parse the input date string
      const dateObject = new Date(val);
      // Format the date as "Month Day Year"
      return dateObject.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
    },
    getDeviceDefaults (deviceType) {
      // Define default values for each device type
      const defaultValues = {
        1: {
          MaxTemperature: 38.0,
          MinTemperature: 36.0,
        },
        2: {
          SpO2: 95,
        },
        3: {
          SystolicMax: 120,
          SystolicMin: 80,
          DiastolicMax: 80,
          DiastolicMin: 60,
          HeartBeatMax: 100,
          HeartBeatMin: 60,
        },
        5: {},
        6: {
          BloodSugarLevelMax: 7.8,
          BloodSugarLevelMin: 4.0,
        },
        7: {},
      };

      return defaultValues[deviceType];
    },

    formatMeasurement (data) {
      var deviceDefaults = this.getDeviceDefaults(parseInt(data.device_type));
      var isAbnormal = false;
      switch (parseInt(data.device_type)) {
        case 1:
          isAbnormal = false;
          if (data?.Temperature_C > deviceDefaults.MaxTemperature || data?.Temperature_C < deviceDefaults.MinTemperature) {
            isAbnormal = true;
          } else {
            isAbnormal = false;
          }

          if (this.userSettings.Units.toLowerCase() === 'metric') {
            if (isAbnormal) {
              return data?.Temperature_C + ' °C (Abnormal)';
            } else {
              return data?.Temperature_C + ' °C (Normal)';
            }
          } else if (this.userSettings.Units.toLowerCase() === 'imperial') {
            if (isAbnormal) {
              return data?.Temperature_F + ' °F (Abnormal)';
            } else {
              return data?.Temperature_F + ' °F (Normal)';
            }
          }
          break;
        case 3:
          isAbnormal = false;

          var systolicChecking = data?.Sys > deviceDefaults.SystolicMax || data?.Sys < deviceDefaults.SystolicMin;
          var diastolicChecking = data?.Dias > deviceDefaults.DiastolicMax || data?.Dias < deviceDefaults.DiastolicMin;
          var heartBeatChecking = data?.Pul > deviceDefaults.HeartBeatMax || data?.Pul < deviceDefaults.HeartBeatMin;

          if (systolicChecking || diastolicChecking || heartBeatChecking) {
            isAbnormal = true;
            return data?.Sys + '/' + data?.Dias + ' mmHg (Abnormal)';
          } else {
            isAbnormal = false;
            return data?.Sys + '/' + data?.Dias + ' mmHg (Normal)';
          }

        case 2:
          isAbnormal = false;

          if (data?.Spo2 < deviceDefaults.SpO2) {
            isAbnormal = true;
            return data?.Spo2 + '% (Abnormal)';
          } else {
            isAbnormal = false;
            return data?.Spo2 + '% (Normal)';
          }
        case 5:
          if (this.userSettings.Units.toLowerCase() === 'metric') {
            return data.weight_kg + ' kg';
          } else return data.weight_lbs + ' lb';
        case 6:
          //  isMeasuredBeforeMeal
          isAbnormal = false;

          var glucoseChecking = data?.glucose_mmolL > deviceDefaults.BloodSugarLevelMax || data?.glucose_mmolL < deviceDefaults.BloodSugarLevelMin;
          if (glucoseChecking) {
            isAbnormal = true;
          } else {
            isAbnormal = false;
          }

          if (isAbnormal) {
            if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
              return parseFloat(data.glucose_mmolL).toFixed(1) + ' mmol/L (Abnormal)';
            } else return parseFloat(data.glucose_mgL).toFixed(1) + ' mg/dL (Abnormal)';
          } else {
            if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
              return parseFloat(data.glucose_mmolL).toFixed(1) + ' mmol/L (Normal)';
            } else return parseFloat(data.glucose_mgL).toFixed(1) + ' mg/dL (Normal)';
          }
        case 7:
          if (this.userSettings.Concentration.toLowerCase() === 'mmol/l') {
            return parseFloat(data.cholesterol_mmolL).toFixed(1) + ' mmol/L';
          } else return parseFloat(data.cholesterol_mgL).toFixed(1) + ' mg/dL';
      }
    },
    calculateAge (dateString) {
      var today = new Date();
      var birthDate = new Date(dateString?.split('T')[0]);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    async getbillingsummaryforallpatientsformonth () {
      this.analyticsLoader = true;
      let year, month;
      if (this.selectedMonthAndYear) {
        [year, month] = this.selectedMonthAndYear.split('-');
      } else {
        const today = new Date();
        year = today.getFullYear();
        month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      }

      const headers = {
        organizationId: this.authUserData.organizationId,
        month: month,
        year: year,
      };
      // DO NOT SEND HOSPITALID IF THE ROLE IS HOSPITAL ADMIN
      if (this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
        headers.hospitalId = localStorage.getItem('selectedHospitalAssignedId');
      }

      if (this.roleName === 'DOCTOR' || this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'NURSE') {
        await this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers);
        this.loading = true;
        var patientNumber = this.billingsFromRepo.length;
        this.billingSummary.cpt99453 = 0;
        this.billingSummary.cpt99454 = 0;
        this.billingSummary.cpt99457 = 0;
        this.billingSummary.cpt994581st = 0;
        this.billingSummary.cpt994582nd = 0;
        if (patientNumber > 0) {
          this.billingsFromRepo.forEach((patient, index) => {
            if (patient.cptCode99453?.status === 1) {
              this.billingSummary.cpt99453++;
            }
            if (patient.cptCode99454?.status === 1) {
              this.billingSummary.cpt99454++;
            }
            if (patient.cptCode99457?.status === 1) {
              this.billingSummary.cpt99457++;
            }
            if (patient.cptCode99458First?.status === 1) {
              this.billingSummary.cpt994581st++;
            }
            if (patient.cptCode99458Second?.status === 1) {
              this.billingSummary.cpt994582nd++;
            }

            let percent99454 = 0;
            let totalMeasurements99454 = 0;
            let measurement99454IsBillable = false;
            let measurement99457IsBillable = false;
            let measurement994581stIsBillable = false;
            let measurement994582ndIsBillable = false;
            if (patient.cptCode99454 !== null) {
              if (patient.cptCode99454.totalMeasurements < 16) {
                percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
                totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
              } else {
                totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
                percent99454 = 100;
                measurement99454IsBillable = true;
              }
            }

            const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

            let percent99457 = 0;
            let percent994581st = 0;
            let percent994582nd = 0;

            let seconds99457 = 0;
            let seconds994581st = 0;
            let seconds994582nd = 0;

            if (totalSec < 1200) {
              seconds99457 = totalSec;
              percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

              seconds994581st = 0;
              percent994581st = 0;

              seconds994582nd = 0;
              percent994582nd = 0;
            }

            if (totalSec >= 1200 && totalSec < 2400) {
              seconds99457 = 1200;
              percent99457 = 100;
              measurement99457IsBillable = true;

              seconds994581st = totalSec - 1200;
              percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

              seconds994582nd = 0;
              percent994582nd = 0;
            }

            if (totalSec >= 2400 && totalSec < 3600) {
              seconds99457 = 1200;
              percent99457 = 100;

              seconds994581st = 1200;
              percent994581st = 100;
              measurement994581stIsBillable = true;

              seconds994582nd = totalSec - 2400;
              percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
            }

            if (totalSec >= 3600) {
              seconds99457 = 1200;
              percent99457 = 100;

              seconds994581st = 1200;
              percent994581st = 100;

              seconds994582nd = totalSec - 2400;
              percent994582nd = 100;
              measurement994582ndIsBillable = true;
            }

            const myBillings = {
              totalMeasurements99454: totalMeasurements99454,
              percent99454: percent99454,
              percent99457: percent99457,
              percent994581st: percent994581st,
              percent994582nd: percent994582nd,
              seconds99457: seconds99457,
              seconds994581st: seconds994581st,
              seconds994582nd: seconds994582nd,
              m99454IsBillable: measurement99454IsBillable,
              m99457IsBillable: measurement99457IsBillable,
              m994581stIsBillable: measurement994581stIsBillable,
              m994582ndIsBillable: measurement994582ndIsBillable,
            };
            patient.myBillings = myBillings;
          });
          this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
          this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
          this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
          this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
          this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);
        }
        this.loading = false;
        this.aggregateData();
        this.calculatePatientStatusForFacility();
        // this.closeAll();

        this.toggleHeaders();
        this.analyticsLoader = false;
      }

      // await this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers).then(res => {
      //
      //
      //
      //   this.toggleHeaders();
      //   this.facilitiesTableLoader = false;
      // });
    },
    toggleHeaders () {
      const table = this.$refs.table;
      if (table) {
        const openCache = table?.$vnode.componentInstance.openCache;
        if (openCache) {
          Object.keys(openCache).forEach(group => {
            this.$set(openCache, group, this.clickedPatient);
          });
        }
      }
      const measurementTable = this.$refs.measurementTable;
      if (measurementTable) {
        const openCache = measurementTable?.$vnode.componentInstance.openCache;
        if (openCache) {
          Object.keys(openCache).forEach(group => {
            this.$set(openCache, group, this.clickedPatient);
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-bottom: 20px;
}
</style>
